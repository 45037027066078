import { Dispatch, SetStateAction, createContext, useContext } from "react";

export interface SettingsContextValues {
    showHeadLinks?: boolean
}

export interface SettingsProviderValues {
    settings: SettingsContextValues
    setSettings: (newSettings: SettingsContextValues) => void
}

export const defaultSettings: SettingsContextValues = {
    showHeadLinks: true
}

export const defaultSettingsProvider: SettingsProviderValues = {
    settings: defaultSettings,
    setSettings(newSettings) {

    },
}

export const SettingsContext = createContext<SettingsProviderValues>(defaultSettingsProvider)

export const useSettingsCtx = () => useContext(SettingsContext)
