import * as React from 'react'
import { SettingsContext, defaultSettings, SettingsContextValues } from '../context/settingsContext'

interface SettingsProviderProps {
    children: React.ReactElement[] | React.ReactElement
}

const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }) => {
    const [settings, setSettings] = React.useState(defaultSettings)

    const newSettings = (newSettings: SettingsContextValues) => {
        setSettings(prev => {
            return {
                ...prev,
                ...newSettings
            }
        })
    }

    return (
        <SettingsContext.Provider value={{ settings, setSettings: newSettings }}>
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsProvider