import "./src/styles.css"
import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import SettingsProvider from "./src/providers/SettingsProvider"

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
    return (
        <SettingsProvider>
            {element}
        </SettingsProvider>
    )
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
    // const pagePath = location ? location.pathname + location.search + location.hash : undefined;

    setTimeout(() => {
        postMessage({ scope: "analytics", msg: "page" })
    }, 100);
}

